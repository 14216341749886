.App {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 0.6); /* You can adjust the opacity here */
}

.App::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: url('background.jpeg');
  background-size: cover;
  opacity: 0.5; /* You can adjust the opacity here */
  z-index: -1;
}

.grid-container {
  display: flex;
  flex-wrap: wrap;
  width: 300px;
  height: 300px;
  border: 1px solid white;
}

.grid-row {
  display: flex;
  width: 100%;
  height: 50px;
  position: relative;
}

.grid-square {
  flex: 1;
  height: 100%;
  border: 1px solid white;
  background-color: #61dafb;
}


.menu-container {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-direction: column;
}

button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: #61dafb;
  cursor: pointer;
  transition: background-color 0.3s;
}
.new-puzzle-button {
  margin-right: 30px;
}
button:hover {
  background-color: #4FAFC1;
}


/* Styles for cell colors */
.grid-square.grey {
    background-color: grey;
}

.grid-square.blue {
    background-color: blue;
}

.grid-square.red {
    background-color: red;
    background-image: radial-gradient(circle, rgba(255,255,255,1) 0%, rgba(0,0,0,0) 20%);

}

.grid-square.green {
    background-color: green;
}
/* New Colors CSS */
.grey {
  background-color: #808080;
}

.red {
  background-color: #FF0000;
}

.green {
  background-color: #00FF00;
}

.blue {
  background-color: #0000FF;
}

.yellow {
  background-color: #bdbd5c;
}

.purple {
  background-color: #800080;
}

.orange {
  background-color: #FFA500;
}

.pink {
  background-color: #FFC0CB;
}

.lime {
  background-color: #00FF00;
}

.cyan {
  background-color: #00FFFF;
}

.magenta {
  background-color: #FF00FF;
}

.brown {
  background-color: #A52A2A;
}

.teal {
  background-color: #008080;
}

.coral {
  background-color: #FF7F50;
}

.navy {
  background-color: #000080;
}

.olive {
  background-color: #808000;
}

.darkblue {
  background-color: #000051;
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.2) 50%, transparent 50%);

}

.darkgreen {
  background-color: #004000;
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.2) 50%, transparent 50%);
}

.darkred {
  background-color: #8B0000;
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.2) 50%, transparent 50%);
}

.darkmagenta {
  background-color: #8B008B;
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.2) 50%, transparent 50%);
}

.darkcyan {
  background-color: #008B8B;
}

.darkgoldenrod {
  background-color: #B8860B;
}

.darkgray {
  background-color: #A9A9A9;
}

.darkkhaki {
  background-color: #BDB76B;
}

.darkolivegreen {
  background-color: #556B2F;
}

.darkorchid {
  background-color: #9932CC;
}

.darksalmon {
  background-color: #E9967A;
}

.white {
  background-color: #FFFFFF;
}

.heading-container {
  display: flex;
  justify-content: space-between;
}

.puzzle-id {
  align-self: flex-start;
  margin-right: 30px;
}

.puzzle-difficulty {
  align-self: flex-end;
  font-weight: bold;
  text-transform: uppercase;
}


.puzzle-difficulty.easy {
  color: green;
}

.puzzle-difficulty.medium {
  color: rgb(124, 89, 23);
}

.puzzle-difficulty.hard {
  color: red;
}
.puzzle-difficulty.bangkok {
  color: orange;
}

/* .target-element {
  
  border-right-width: 1px;
  border-right-color: red;
  border-right-style: solid;
} */

.target-element::after {
  content: "";
  width: 0; 
  height: 0; 
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 20px solid rgba(255, 0, 0, 0.356); /* Arrow color */
  position: absolute;
  margin-left: 55px; /* Adjust this to position the arrow correctly */
  box-shadow: 0px 0 0 0 black; /* The shadow that forms the arrow, adjust as needed */
  top: 50%; /* Position from the top, halfway down the parent */
  transform: translateY(-50%); /* Move it up by half of its own height */
}
